import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "react-toastify/dist/ReactToastify.css";
import "./ModelView.css";
import Modal from "react-modal";
import UpdateForm from "../UpdateForm/UpdateForm";
import Header from "../../../../Components/BackEnd/Backend/Header";

Modal.setAppElement("#root");

const firebaseConfig = {
  apiKey: "AIzaSyAmwKwXojF0ucF1SxgzenRn38UhhJHqz3Y",
  authDomain: "agencysl.firebaseapp.com",
  projectId: "agencysl",
  storageBucket: "agencysl.appspot.com",
  messagingSenderId: "928828761215",
  appId: "1:928828761215:web:d0b58236a291f931500b4a",
  measurementId: "G-TF2XE8K2TJ",
};

firebase.initializeApp(firebaseConfig);

const ModelView = () => {
  const [models, setModels] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedModel, setSelectedModel] = useState(null);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

  useEffect(() => {
    const fetchModels = async () => {
      const db = firebase.firestore();
      const modelsCollection = db.collection("models");
      const snapshot = await modelsCollection.get();
      const modelsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setModels(modelsData);
    };

    fetchModels();
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this model?")) {
      try {
        const db = firebase.firestore();
        await db.collection("models").doc(id).delete();
        setModels(models.filter((model) => model.id !== id));
        alert("Model deleted successfully!");
      } catch (error) {
        console.error("Error deleting document: ", error);
      }
    }
  };

  const handleUpdate = (modelId) => {
    const modelToUpdate = models.find((model) => model.id === modelId);
    setSelectedModel(modelToUpdate);
    setIsUpdateModalOpen(true);
  };

  const handleCloseForm = () => {
    setSelectedModel(null);
    setIsUpdateModalOpen(false);
  };

  const filteredModels = models.filter((model) =>
    model.Name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleArchive = async (id) => {
    if (window.confirm("Are you sure you want to archive this model?")) {
      try {
        const db = firebase.firestore();
        // Get the model data
        const modelToArchive = { ...models.find((model) => model.id === id) };
        // Remove the 'id' field from the model data
        delete modelToArchive.id;
        // Add the model to the "archive" collection
        await db.collection("archive").add(modelToArchive);
        // Delete the model from the "models" collection
        await db.collection("models").doc(id).delete();
        // Update the local state to remove the archived model
        setModels(models.filter((model) => model.id !== id));
        alert("Model archived successfully!");
      } catch (error) {
        console.error("Error archiving model: ", error);
      }
    }
  };
  

  return (
    <>
      <Header />

      <div className="model-view-container">
        <h2>Model Data</h2>
        <input
          type="text"
          className="search-input"
          placeholder="Search by Model Name"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <table>
          <thead>
            <tr>
              <th>Model Name</th>
              <th>Gender</th>
              <th>Age</th>
              <th>Category</th>
              <th>Height</th>
              <th>Waist</th>
              <th>Bust</th>
              <th>Hips</th>
              <th>Shoe</th>
              <th>Hair</th>
              <th>Eye</th>
              <th>Social Media Link</th>
              <th>Image 1</th>
              <th>Image 2</th>
              <th>Image 3</th>
              <th>Image 4</th>
              <th>Image 5</th>
              <th>Image 6</th>
              <th>Image 7</th>
              <th>Image 8 (Polaroid)</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredModels.map((model) => (
              <tr key={model.id}>
                <td>{model.Name}</td>
                <td>{model.Gender}</td>
                <td>{model.Age}</td>
                <td>{model.Category}</td>
                <td>{model.Height}</td>
                <td>{model.Waist}</td>
                <td>{model.Bust}</td>
                <td>{model.Hips}</td>
                <td>{model.Shoe}</td>
                <td>{model.Hair}</td>
                <td>{model.Eyes}</td>
                <td><a href={model.SocialMediaLink}>{model.SocialMediaLink}</a></td>

                <td>
                  <img
                    src={model.Image}
                    alt="Model-img"
                    className="model-photo"
                  />
                </td>
                <td>
                  <img
                    src={model.Image2}
                    alt="Model-img2"
                    className="model-photo"
                  />
                </td>
                <td>
                  <img
                    src={model.Image3}
                    alt="Model-img3"
                    className="model-photo"
                  />
                </td>
                <td>
                  <img
                    src={model.Image4}
                    alt="Model-img4"
                    className="model-photo"
                  />
                </td>
                <td>
                  <img
                    src={model.Image5}
                    alt="Model-img4"
                    className="model-photo"
                  />
                </td>
                <td>
                  <img
                    src={model.Image6}
                    alt="Model-img4"
                    className="model-photo"
                  />
                </td>
                <td>
                  <img
                    src={model.Image7}
                    alt="Model-img4"
                    className="model-photo"
                  />
                </td>
                <td>
                  <img
                    src={model.Image8}
                    alt="Model-img4"
                    className="model-photo"
                  />
                </td>
                <td>
                  <button
                    className="edit-btn"
                    onClick={() => handleUpdate(model.id)}
                  >
                    Update
                  </button>
                  <button
                    className="delete-btn"
                    onClick={() => handleDelete(model.id)}
                  >
                    Delete
                  </button>
                  <button
                    className="archive-btn" // Add a class for styling if needed
                    onClick={() => handleArchive(model.id)} // Define a function to handle archiving
                  >
                    Archive
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <Modal
          isOpen={isUpdateModalOpen}
          onRequestClose={handleCloseForm}
          contentLabel="Update Model Modal"
        >
          {selectedModel && (
            <UpdateForm model={selectedModel} onCloseForm={handleCloseForm} />
          )}
        </Modal>
      </div>
    </>
  );
};

export default ModelView;
