import React, { useState, useEffect } from "react";
import "./SideMenu.css"; 
import { Link } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { FaAngleDown, FaBars, FaTimes } from "react-icons/fa"; 

const SideMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isTalentDropdownOpen, setIsTalentDropdownOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [menuIcon, setMenuIcon] = useState(<FaBars />);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleTalentClick = () => {
    setIsTalentDropdownOpen(!isTalentDropdownOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const threshold = 50; 

      setIsScrolled(scrollPosition > threshold);
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setMenuIcon(isMenuOpen ? <FaTimes /> : <FaBars />);
  }, [isMenuOpen]);

  return (
    <div
      className={`side-menu-container ${isMenuOpen ? "open" : ""} ${
        isScrolled ? "hidden" : ""
      }`}
    >
      <div
        className={`menu-icon ${isScrolled ? "hidden" : ""}`}
        onClick={toggleMenu}
      >
        <Link to="/" className="sideimage">
          <img src= "../Assets/logo.svg" alt="Image" className="imagelogo" />
        </Link>
        <div className="menu-toggle-icon">{menuIcon}</div>
      </div>

      <ul className="mainmen">
        <li onClick={handleTalentClick}>
          MODELS <FaAngleDown /> {/* Add dropdown icon here */}
          {isTalentDropdownOpen && (
            <ul className="malefemale">
              <li>
                <Link to="/modelMale">MALE</Link>
              </li>
              <li>
                <Link to="/modelfemale">FEMALE</Link>
              </li>
            </ul>
          )}
        </li>
        <li>
          <Link to="/actors">ACTORS</Link>
        </li>
        <li>
          <Link to="/hair&makeupartists">HAIR & MAKEUP ARTISTS</Link>
        </li>
        <li>
          <Link to="/photographers">PHOTOGRAPHERS</Link>
        </li>
        <li>
          <Link to="/videographers">VIDEOGRAPHERS</Link>
        </li>
        <li>
          <Link to="/hairstylists">STYLISTS</Link>
        </li>
      </ul>
      <div className="side-menu-columns">
        <div className="sidemenu-subs">
          <ul className="submenu">
            <li>
              <Link to="/media">MEDIA</Link>
            </li>
            <li>
              <Link to="/about">ABOUT</Link>
            </li>
            <li>
              <Link to="/apply">APPLY</Link>
            </li>
            <li>
              <Link to="/contact">CONTACT</Link>
            </li>
          </ul>
        </div>

        <div className="sidemenu-subs2">
          <ul className="submenu">
            <li>
              <RouterLink to="https://www.instagram.com/theagencysrilanka/?igsh=OHF2ajZyczBleXVi" target="_blank"> 
                INSTAGRAM
              </RouterLink>
            </li>
            <li>
              <RouterLink to="https://www.facebook.com/theagencysrilanka" target="_blank">
                FACEBOOK
              </RouterLink>
            </li>
            <li>
              <RouterLink to="https://www.youtube.com/@Theagencysrilanka" target="_blank">
                YOUTUBE
              </RouterLink>
            </li>
            <li>
              <RouterLink to="https://www.linkedin.com/company/the-agency-sri-lanka" target="_blank">
                LINKEDIN
              </RouterLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
