import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "./BlogForm.css"; 
import Header from "../../../../Components/BackEnd/Backend/Header";

const firebaseConfig = {
  apiKey: "AIzaSyAmwKwXojF0ucF1SxgzenRn38UhhJHqz3Y",
  authDomain: "agencysl.firebaseapp.com",
  projectId: "agencysl",
  storageBucket: "agencysl.appspot.com",
  messagingSenderId: "928828761215",
  appId: "1:928828761215:web:d0b58236a291f931500b4a",
  measurementId: "G-TF2XE8K2TJ",
};

firebase.initializeApp(firebaseConfig);

const BlogForm = () => {
  const [blogName, setBlogName] = useState("");
  const [modelName, setModelName] = useState("");
  const [blogPhoto, setBlogPhoto] = useState(null);
  const [date, setDate] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!blogName || !modelName || !blogPhoto || !date) {
      setErrorMessage("Please fill in all fields.");
      return;
    }

    const storageRef = firebase.storage().ref();
    const imageRef = storageRef.child(`blog_images/${blogPhoto.name}`);
    await imageRef.put(blogPhoto);
    const photoURL = await imageRef.getDownloadURL();

    const db = firebase.firestore();
    await db.collection("blogs").add({
      blogName,
      modelName,
      blogPhoto: photoURL,
      date: new Date(date),
    });

    alert("Blog details saved successfully!");

    // Reset state values
    setBlogName("");
    setModelName("");
    setBlogPhoto(null);
    setDate("");
    setErrorMessage("");

    // Redirect to home page
    navigate("/blogview");
  };

  return (
    <>
    <Header/>
      <div className="blog-form-container">
        <h2>Add Blog Details</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Blog Name"
            value={blogName}
            onChange={(e) => setBlogName(e.target.value)}
            className="blog-input"
            required
          />
          <input
            type="text"
            placeholder="Model Name"
            value={modelName}
            onChange={(e) => setModelName(e.target.value)}
            className="blog-input"
            required
          />
          <input
            type="file"
            onChange={(e) => setBlogPhoto(e.target.files[0])}
            className="blog-file-input"
            required
          />
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            className="blog-input"
            required
          />
          <button type="submit" className="blog-submit">
            Submit
          </button>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </form>
      </div>
    </>
  );
};

export default BlogForm;
