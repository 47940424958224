import { useRef, useState } from "react";
import { FaPhone, FaEnvelope } from "react-icons/fa";
import "./Contact.css";
import Sidemenu from "../SideMenu/SideMenu.jsx";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const form = useRef();
  const [showPopup, setShowPopup] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_3i7rxmf", "template_5atfxam", form.current, {
        publicKey: "ytxXschC3dGlUlYcj",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          setShowPopup(true);
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
    e.target.reset();
  };

  return (
    <div className="contact_main">
      <Sidemenu />

      <div className="contact_cover-image-box">
        <img
          src="Assets/Sashini.jpg"
          alt="Cover Image"
          className="contact_cover-image"
        />
      </div>

      <br />
      <br />
      <br />
      <br />

      <div className="contact-container">
        <div className="contact-info">
          <h1>
            <b>Contact</b>
          </h1>
          <div className="middle">
            <br />
            <div className="contact-icon">
              <FaPhone className="icon" />
              <p>+94 777 637 137</p>
            </div>
            <br />
            <div className="contact-icon">
              <FaEnvelope className="icon" />
              <p>info@theagencysrilanka.com</p>
            </div>
          </div>
        </div>

        <div className="contact-form-frontend">
          <div className="contact-topic">
            <h1>Get In Touch With Us</h1>
          </div>
          <br />
          <br />
          <br />
          <br />
          <div className="contact-mainform">
            <form ref={form} onSubmit={sendEmail}>
              <label htmlFor="name">Full Name*</label>
              <br />
              <input type="text" id="name" name="name" required />
              <br />
              <br />
              <br />

              <label htmlFor="subject">Subject*</label>
              <br />
              <input type="text" id="subject" name="subject" required />
              <br />
              <br />
              <br />

              <label htmlFor="email">Email*</label>
              <br />
              <input type="email" id="email" name="email" required />
              <br />
              <br />
              <br />

              <label htmlFor="phone">Phone*</label>
              <br />
              <input type="text" id="phone" name="phone" required />
              <br />
              <br />
              <br />

              <label htmlFor="message">Message*</label>
              <br />
              <textarea id="message" name="message" rows="4" required></textarea>
              <br />
              <br />
              <br />

              <button
                className="contactsubmit-frontend"
                id="contact-submit-button"
                type="submit"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />

      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <h2>Thank you for contacting us!</h2>
            <p>We will get back to you as soon as possible.</p>
            <button onClick={() => setShowPopup(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Contact;
