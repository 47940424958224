// App.jsx
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./routes/FrontEnd/Home/Home.jsx";
import Actors from "./routes/FrontEnd/Talent/Actors.jsx";
import Contact from "./routes/FrontEnd/Contact/Contact.jsx";
import Apply from "./routes/FrontEnd/Apply/Apply.jsx";
import About from "./routes/FrontEnd/About/About.jsx";
import Media from "./routes/FrontEnd/Media/Media.jsx";
import Profile from "./routes/FrontEnd/Profile/Profile.jsx";
import Portfolio from "./routes/FrontEnd/Portfolio/Portfolio.jsx";
import MakeupArtists from "./routes/FrontEnd/Talent/HairMakeup.jsx";
import Photographers from "./routes/FrontEnd/Talent/Photograpers.jsx";
import Videographer from "./routes/FrontEnd/Talent/Videographer.jsx";
import Hairstylists from "./routes/FrontEnd/Talent/Stylist.jsx";
import ModelMale from "./routes/FrontEnd/Talent/ModelMale.jsx";
import ModelFemale from "./routes/FrontEnd/Talent/ModelFemale.jsx";
import Footer from "./Components/FrontEnd/Footer/Footer.jsx";

// Imports for the Backend
import BlogForm from './routes/BackEnd/Blogs/BlogForm/BlogForm.jsx';
import BlogView from './routes/BackEnd/Blogs/BlogView/BlogView.jsx';
import ModelForm from './routes/BackEnd/Models/ModelForm/ModelForm.jsx';
import ModelView from './routes/BackEnd/Models/ModelView/ModelView.jsx';
import ArchiveView from './routes/BackEnd/Models/ArchiveView/ArchiveView.jsx'
import Login from './routes/BackEnd/Login/Login.jsx';

function App() {
  return (
    <Router>
      <div className="App">
        <div className="content-wrap">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/actors" element={<Actors />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/apply" element={<Apply />} />
            <Route path="/about" element={<About />} />
            <Route path="/media" element={<Media />} />
            <Route path="/profile/:id" element={<Profile />} />
            <Route path="/portfolio/:id" element={<Portfolio />} />
            <Route path="/hair&makeupartists" element={<MakeupArtists />} />
            <Route path="/photographers" element={<Photographers />} />
            <Route path="/videographers" element={<Videographer />} />
            <Route path="/hairstylists" element={<Hairstylists />} />
            <Route path="/modelMale" element={<ModelMale />} />
            <Route path="/modelfemale" element={<ModelFemale />} />

            {/* Routes for Backend */}
            <Route path="/portal" element={<Login />} />
            <Route path="/modelview" element={<ModelView />} />
            <Route path="/blogform" element={<BlogForm />} />
            <Route path="/modelform" element={<ModelForm />} />
            <Route path="/blogview" element={<BlogView />} />
            <Route path="/archiveView" element={<ArchiveView />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
