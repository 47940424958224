import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "./ArchiveView.css";
import Header from "../../../../Components/BackEnd/Backend/Header";

const ArchiveView = () => {
  const [models, setModels] = useState([]);

  useEffect(() => {
    const fetchModels = async () => {
      const db = firebase.firestore();
      const archiveCollection = db.collection("archive");
      const snapshot = await archiveCollection.get();
      const archiveData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setModels(archiveData);
    };

    fetchModels();
  }, []);

  const handleUnarchive = async (id, modelData) => {
    if (window.confirm("Are you sure you want to unarchive this model?")) {
      try {
        const db = firebase.firestore();

        // Delete model from the "archive" collection
        await db.collection("archive").doc(id).delete();

        // Remove the "id" field from the model data
        const { id: modelId, ...modelWithoutId } = modelData;

        // Add model to the "models" collection without the "id" field
        await db.collection("models").add(modelWithoutId);

        // Update local state to remove the unarchived model
        setModels((prevModels) =>
          prevModels.filter((archive) => archive.id !== id)
        );

        alert("Model unarchived successfully!");
      } catch (error) {
        console.error("Error unarchiving model:", error);
      }
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this model?")) {
      try {
        const db = firebase.firestore();

        // Delete model from the "archive" collection
        await db.collection("archive").doc(id).delete();

        // Update local state to remove the deleted model
        setModels((prevModels) =>
          prevModels.filter((archive) => archive.id !== id)
        );

        alert("Model deleted successfully!");
      } catch (error) {
        console.error("Error deleting model:", error);
      }
    }
  };

  return (
    <>
      <Header />
      <div className="archive-page">
        <h2>Archived Models</h2>
        <table>
          <thead>
            <tr>
              <th>Model Name</th>
              <th>Gender</th>
              <th>Age</th>
              <th>Category</th>
              <th>Height</th>
              <th>Waist</th>
              <th>Bust</th>
              <th>Hips</th>
              <th>Shoe</th>
              <th>Hair</th>
              <th>Eye</th>
              <th>Social Media Link</th>
              <th>Image 1</th>
              <th>Image 2</th>
              <th>Image 3</th>
              <th>Image 4</th>
              <th>Image 5</th>
              <th>Image 6</th>
              <th>Image 7</th>
              <th>Image 8 (Polaroid)</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {models.map((archive) => (
              <tr key={archive.id}>
                <td>{archive.Name}</td>
                <td>{archive.Gender}</td>
                <td>{archive.Age}</td>
                <td>{archive.Category}</td>
                <td>{archive.Height}</td>
                <td>{archive.Waist}</td>
                <td>{archive.Bust}</td>
                <td>{archive.Hips}</td>
                <td>{archive.Shoe}</td>
                <td>{archive.Hair}</td>
                <td>{archive.Eyes}</td>
                <td>{archive.SocialMediaLink}</td>
                <td>
                  <img
                    src={archive.Image}
                    alt={`Model ${archive.Name} - Image 1`}
                    className="model-photo"
                  />
                </td>
                <td>
                  <img
                    src={archive.Image2}
                    alt={`Model ${archive.Name} - Image 2`}
                    className="model-photo"
                  />
                </td>
                <td>
                  <img
                    src={archive.Image3}
                    alt={`Model ${archive.Name} - Image 3`}
                    className="model-photo"
                  />
                </td>
                <td>
                  <img
                    src={archive.Image4}
                    alt={`Model ${archive.Name} - Image 4`}
                    className="model-photo"
                  />
                </td>
                <td>
                  <img
                    src={archive.Image5}
                    alt={`Model ${archive.Name} - Image 5`}
                    className="model-photo"
                  />
                </td>
                <td>
                  <img
                    src={archive.Image6}
                    alt={`Model ${archive.Name} - Image 6`}
                    className="model-photo"
                  />
                </td>
                <td>
                  <img
                    src={archive.Image7}
                    alt={`Model ${archive.Name} - Image 7`}
                    className="model-photo"
                  />
                </td>
                <td>
                  <img
                    src={archive.Image8}
                    alt={`Model ${archive.Name} - Image 8 (Polaroid)`}
                    className="model-photo"
                  />
                </td>
                <td>
                  <button
                    className="delete-btn"
                    onClick={() => handleDelete(archive.id)}
                  >
                    Delete
                  </button>
                  <button
                    className="archive-btn"
                    onClick={() => handleUnarchive(archive.id, archive)}
                  >
                    Unarchive
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ArchiveView;
