import { Link } from "react-router-dom";
import React, { useState } from "react";
import { FaAngleDown } from "react-icons/fa";
import "./Header.css";

const Header = () => {
  const [isTalentDropdownOpen, setIsTalentDropdownOpen] = useState(false);

  const handleTalentClick = () => {
    setIsTalentDropdownOpen(!isTalentDropdownOpen);
  };

  return (
    <div className="vertical-header">
      <ul>
        <li>
          <img src="Assets/logo.svg" alt="Image" className="imagelogo" />
        </li>

        <li onClick={handleTalentClick} className="Modelhead">
          MODELS <FaAngleDown /> {/* Add dropdown icon here */}
          {isTalentDropdownOpen && (
            <ul className="malefemale-header">
              <li>
                <Link to="/modelMale">MALE</Link>
              </li>
              <li>
                <Link to="/modelfemale">FEMALE</Link>
              </li>
            </ul>
          )}
        </li>
        <li>
          <Link to="/actors">ACTORS</Link>
        </li>
        <li>
          <Link to="/hair&makeupartists">HAIR & MAKEUP ARTISTS</Link>
        </li>
        <li>
          <Link to="/photographers">PHOTOGRAPHERS</Link>{" "}
        </li>
        <li>
          <Link to="/videographerS">VIDEOGRAPHERS</Link>{" "}
        </li>
        <li>
          <Link to="/hairstylists">STYLISTS</Link>{" "}
        </li>
      </ul>
    </div>
  );
};

export default Header;
