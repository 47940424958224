// Footer.jsx
import React from 'react';
import './Footer.css'; // Optional: if you want to add some custom styles

const Footer = () => {
  return (
    <footer className="footer">
      <p>
        © 2024 The Agency Sri Lanka | All Rights Reserved<span className="separator"> | </span>‏ Designed and Developed by ‏
        <a href="https://alchemy.lk" target="_blank" rel="noopener noreferrer">
           Alchemy
        </a>
      </p>
    </footer>
  );
}

export default Footer;