import React, { useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "./UpdateForm.css";

const UpdateForm = ({ model, onCloseForm }) => {
  const [name, setName] = useState(model.Name);
  const [gender, setGender] = useState(model.Gender);
  const [age, setAge] = useState(model.Age);
  const [category, setCategory] = useState(model.Category);
  const [height, setHeight] = useState(model.Height);
  const [waist, setWaist] = useState(model.Waist);
  const [bust, setBust] = useState(model.Bust);
  const [hips, setHips] = useState(model.Hips);
  const [shoe, setShoe] = useState(model.Shoe);
  const [hair, setHair] = useState(model.Hair);
  const [eyes, setEye] = useState(model.Eyes);
  const [socialMediaLink, setSocialMediaLink] = useState(model.SocialMediaLink);
  const [image, setImage] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [image4, setImage4] = useState(null);
  const [image5, setImage5] = useState(null);
  const [image6, setImage6] = useState(null);
  const [image7, setImage7] = useState(null);
  const [image8, setImage8] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !name ||
      !gender ||
      !age ||
      !category ||
      !height ||
      !waist ||
      !bust ||
      !hips ||
      !shoe ||
      !hair ||
      !eyes ||
      !socialMediaLink
    ) {
      alert("Please fill in all the required fields.");
      return;
    }

    try {
      const db = firebase.firestore();
      const modelRef = db.collection("models").doc(model.id);

      await modelRef.update({
        Name: name,
        Gender: gender,
        Age: age,
        Category: category,
        Height: height,
        Waist: waist,
        Bust: bust,
        Hips: hips,
        Shoe: shoe,
        Hair: hair,
        Eyes: eyes,
        SocialMediaLink: socialMediaLink,
        Image: image ? await uploadImage(image, "image") : model.Image,
        Image2: image2 ? await uploadImage(image2, "image2") : model.Image2,
        Image3: image3 ? await uploadImage(image3, "image3") : model.Image3,
        Image4: image4 ? await uploadImage(image4, "image4") : model.Image4,
        Image5: image5 ? await uploadImage(image5, "image5") : model.Image5,
        Image6: image6 ? await uploadImage(image6, "image6") : model.Image6,
        Image7: image7 ? await uploadImage(image7, "image7") : model.Image7,
        Image8: image8 ? await uploadImage(image8, "image8") : model.Image8,
      });

      alert("Model details updated successfully!");
      onCloseForm();
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  const handleImageChange = (e, setImageFunction) => {
    if (e.target.files[0]) {
      setImageFunction(e.target.files[0]);
    }
  };

  const uploadImage = async (file, imageName) => {
    const storageRef = firebase.storage().ref();
    const timestamp = Date.now(); // Get current timestamp
    const uniqueFilename = `${imageName}_${timestamp}`; // Append timestamp to filename
    const fileRef = storageRef.child(`model_images/${uniqueFilename}`);
    await fileRef.put(file);
    return fileRef.getDownloadURL();
  };
  

  return (
    <div className="model-form-container">
      <h2>Update Model Data</h2>
      <form onSubmit={handleSubmit} className="update-form">
        <div className="form-group">
          <label>Model Name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Gender</label>
          <label>
            <input
              type="radio"
              id="male"
              name="gender"
              value="Male"
              checked={gender === "Male"}
              onChange={(e) => setGender(e.target.value)}
            />
            Male
          </label>
          <label>
            <input
              type="radio"
              id="female"
              name="gender"
              value="Female"
              checked={gender === "Female"}
              onChange={(e) => setGender(e.target.value)}
            />
            Female
          </label>
        </div>
        <div className="form-group">
          <label>Age</label>
          <input
            type="number"
            value={age}
            onChange={(e) => setAge(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Category</label>
          <select
            name="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
          >
            <option value="Models">Models</option>
            <option value="Actors">Actors</option>
            <option value="Hair & Makeup Artists">Hair & Makeup Artists</option>
            <option value="Photographers">Photographers</option>
            <option value="Videographers">Videographers</option>
            <option value="Stylists">Stylists</option>
          </select>
        </div>
        <div className="form-group">
          <label>Height</label>
          <input
            type="text"
            value={height}
            onChange={(e) => setHeight(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Waist</label>
          <input
            type="number"
            value={waist}
            onChange={(e) => setWaist(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Bust</label>
          <input
            type="number"
            value={bust}
            onChange={(e) => setBust(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Hips</label>
          <input
            type="number"
            value={hips}
            onChange={(e) => setHips(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Shoe</label>
          <input
            type="number"
            value={shoe}
            onChange={(e) => setShoe(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Hair Color</label>
          <input
            type="text"
            value={hair}
            onChange={(e) => setHair(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Eye Color</label>
          <input
            type="text"
            value={eyes}
            onChange={(e) => setEye(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Social Media Link</label>
          <input
            type="text"
            value={socialMediaLink}
            onChange={(e) => setSocialMediaLink(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Image 1 (1x1)</label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleImageChange(e, setImage)}
          />
        </div>
        <div className="form-group">
          <label>Image 2 (1x1)</label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleImageChange(e, setImage2)}
          />
        </div>
        <div className="form-group">
          <label>Image 3</label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleImageChange(e, setImage3)}
          />
        </div>
        <div className="form-group">
          <label>Image 4</label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleImageChange(e, setImage4)}
          />
        </div>
        <div className="form-group">
          <label>Image 5</label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleImageChange(e, setImage5)}
          />
        </div>
        <div className="form-group">
          <label>Image 6</label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleImageChange(e, setImage6)}
          />
        </div>
        <div className="form-group">
          <label>Image 7</label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleImageChange(e, setImage7)}
          />
        </div>
        <div className="form-group">
          <label>Image 8 (Polaroid)</label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleImageChange(e, setImage8)}
          />
        </div>
        <button type="submit">Update</button>
      </form>
    </div>
  );
};

export default UpdateForm;
