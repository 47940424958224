import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import Sidemenu from "../SideMenu/SideMenu.jsx";
import "./Apply.css";

const Apply = () => {
  const [showPopup, setShowPopup] = useState(false);
  const formRef = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_3i7rxmf", "template_q6lmurt", formRef.current, {
        publicKey: "ytxXschC3dGlUlYcj",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          setShowPopup(true);
        },
        (error) => {
          console.log("FAILED...", error.text);
          setShowPopup(true);
        }
      );

    e.target.reset();
  };

  window.addEventListener("DOMContentLoaded", (event) => {
    const textarea = document.querySelector(".form-grid .msg");
    textarea.scrollTop = textarea.scrollHeight;
  });

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Handle the file here
      console.log("Selected file:", file);
    }
  };

  return (
    <div className="apply-container">
      <Sidemenu />
      <h2>APPLY</h2>
      <p>
        To start, please fill ou t the form below accurately. Our team will
        review your submission and contact you if there's potential. Please be
        patient as we process many submissions. If we're interested, we'll
        schedule a face-to-face interview. If that goes well and we decide to
        sign you, the next step is professional photos and portfolio building.
        We'll guide you through it.Good luck!
      </p>
      <h1>1. CONTACT INFO</h1>

      <form className="form-grid" ref={formRef} onSubmit={sendEmail}>
        <div className="form-column">
          <div className="form-field">
            <label>
              Full Name*
              <br />
              <input type="text" name="name" id="apply-name" required />
            </label>
          </div>
          <div className="form-field">
            <label>
              Email*
              <br />
              <input type="email" name="email" id="apply-email" required />
            </label>
          </div>
          <div className="form-field">
            <label>
              Mobile*
              <br />
              <input type="tel" name="phoneNumber" id="apply-phoneNumber" required />
            </label>
          </div>
          <div className="form-field">
            <label>
              Social Media Link*
              <br />
              <input type="text" name="social_link" id="apply-social" required />
            </label>
          </div>
          <div className="form-field">
            <label>
              Address*
              <br />
              <input type="text" name="address" id="apply-address" required />
            </label>
          </div>
          <div className="form-field">
            <label>
              Date Of Birth*
              <br />
              <input type="text" name="DOB" id="apply-dob" required />
            </label>
          </div>

          <div className="form-field">
            <label>
              Country*
              <br />
              <input type="text" name="country" id="apply-country" required />
            </label>
          </div>
          <div className="form-field">
            <label>
              City*
              <br />
              <input type="text" name="city" id="apply-city" required />
            </label>
          </div>
        </div>

        <h1>2. YOUR STORY</h1>
        <p>
          Briefly tell us about yourself. Where are you from? What are your
          goals?*
        </p>
        <textarea
          className="msg"
          type="text"
          name="message"
          rows="4"
          required
        />

        <h1>3. MEASUREMENTS</h1>
        <p>
          To ensure the most accurate measurements, please wear a bikini and
          have someone you are comfortable with measure you. Please use a
          measuring tape.
        </p>

        <div className="form-column2">
          <div className="measurements">
            <label>
              Bust*
              <br />
              <input type="text" name="bust" id="apply-bust" required />
            </label>
          </div>
          <div className="measurements">
            <label>
              Waist*
              <br />
              <input type="text" name="waist" id="apply-waist"required />
            </label>
          </div>
          <div className="measurements">
            <label>
              Hips*
              <br />
              <input type="text" name="hips" id="apply-hips"required />
            </label>
          </div>
          <div className="measurements">
            <label>
              Height*
              <br />
              <input type="text" name="height" id="apply-height" required />
            </label>
          </div>
          <div className="measurements">
            <label>
              Shoe*
              <br />
              <input type="text" name="shoe" id="apply-shoe" required />
            </label>
          </div>
          <div className="measurements">
            <label>
              Hair*
              <br />
              <input type="text" name="hair" id="apply-hair" required />
            </label>
          </div>
        </div>

        <div className="terms-checkbox">
          <label>
            <input type="checkbox" name="terms" required />
            &nbsp;&nbsp;I have read and accept the application and the terms and
            conditions of the privacy policy.
          </label>
        </div>

        <div className="submit-button-apply">
          <button type="submit" id="apply-submit">Submit</button>
        </div>
      </form>

      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <h2>Application Submitted!</h2>
            <p>We'll get back to you as soon as possible.</p>
            <button onClick={() => setShowPopup(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Apply;
