import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Talent.css";
import Sidemenu from "../SideMenu/SideMenu.jsx";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, query, where } from "firebase/firestore";

// Updated SmallCard component
const SmallCard = ({ details }) => {
  const cardStyle = {
    backgroundImage: `url(${details.Image3})`,
  };

  return (
    <div className="one_card">
      <Link to={`/profile/${details.id}`} className="talent_card" style={cardStyle}>
        <div className="talent_details_wrapper">
          <div className="talent_details">
            <h4>Height {details.Height}</h4>
            <h4>Bust {details.Bust}</h4>
            <h4>Waist {details.Waist}</h4>
            <h4>Hips {details.Hips}</h4>
            <h4>Shoe {details.Shoe}</h4>
            <h4>Hair {details.Hair}</h4>
            <h4>Eyes {details.Eyes}</h4>
          </div>
        </div>
      </Link>
      <div className="name_container">
        <Link to={`/profile/${details.id}`} className="model_name">{details.Name}</Link>
      </div>
    </div>
  );
};

const firebaseConfig = {
  apiKey: "AIzaSyAmwKwXojF0ucF1SxgzenRn38UhhJHqz3Y",
  authDomain: "agencysl.firebaseapp.com",
  projectId: "agencysl",
  storageBucket: "agencysl.appspot.com",
  messagingSenderId: "928828761215",
  appId: "1:928828761215:web:d0b58236a291f931500b4a",
  measurementId: "G-TF2XE8K2TJ"};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const ModelMale = () => {
  const [cardData, setCardData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const modelsCollectionRef = collection(db, "models");
      
      // Query only models where the category is "Actors"
      const q = query(modelsCollectionRef, where("Category", "==", "Models"), where("Gender", "==", "Female"));
      const querySnapshot = await getDocs(q);

      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      console.log("Fetched data:", data);

      setCardData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className={`talent_main`}>
      <Sidemenu />
      {cardData.map((card) => (
        <SmallCard key={card.id} details={card} />
      ))}
    </div>
  );
};

export default ModelMale;
