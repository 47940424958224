import React, { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import "./Media.css";
import Sidemenu from "../SideMenu/SideMenu.jsx";

const Media = () => {
  const [blogPosts, setBlogPosts] = useState([]);

  // Firebase configuration
  const firebaseConfig = {
    apiKey: "AIzaSyAmwKwXojF0ucF1SxgzenRn38UhhJHqz3Y",
    authDomain: "agencysl.firebaseapp.com",
    projectId: "agencysl",
    storageBucket: "agencysl.appspot.com",
    messagingSenderId: "928828761215",
    appId: "1:928828761215:web:d0b58236a291f931500b4a",
    measurementId: "G-TF2XE8K2TJ",
  };

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  useEffect(() => {
    const fetchBlogPosts = async () => {
      try {
        const blogPostsCollection = collection(db, "blogs");
        const querySnapshot = await getDocs(blogPostsCollection);

        const data = querySnapshot.docs.map((doc) => {
          const postData = doc.data();
          return {
            id: doc.id,
            ...postData,
            // Convert Firestore Timestamp to Date
            date: postData.date.toDate(),
          };
        });

        setBlogPosts(data);
      } catch (error) {
        console.error("Error fetching blog posts:", error);
      }
    };

    fetchBlogPosts();
  }, [db]);

  // Function to format date as "DD Mon YYYY"
  const formatDate = (date) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <div className="media-container">
      <Sidemenu />
      <h2>MEDIA</h2>
      <div className="blog-posts-grid">
        {blogPosts.map((post) => (
          <div key={post.id} className="blog-post">
            <img src={post.blogPhoto} alt="Blog_Photo" />
            <h3>{post.blogName}</h3>
            <p>Model : {post.modelName}</p>
            {/* Format date using formatDate function */}
            <p>{formatDate(post.date)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Media;
