import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom"; // Import Link
import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import Sidemenu from "../SideMenu/SideMenu.jsx"; // Import Sidemenu component
import "./Profile.css";

const Profile = () => {
  const { id } = useParams();
  const [modelDetails, setModelDetails] = useState(null);

  // Firebase configuration
  const firebaseConfig = {
    apiKey: "AIzaSyAmwKwXojF0ucF1SxgzenRn38UhhJHqz3Y",
    authDomain: "agencysl.firebaseapp.com",
    projectId: "agencysl",
    storageBucket: "agencysl.appspot.com",
    messagingSenderId: "928828761215",
    appId: "1:928828761215:web:d0b58236a291f931500b4a",
    measurementId: "G-TF2XE8K2TJ",
  };

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  useEffect(() => {
    const fetchModelDetails = async () => {
      try {
        const modelDoc = doc(db, "models", id);
        const docSnap = await getDoc(modelDoc);
        if (docSnap.exists()) {
          setModelDetails(docSnap.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching model details:", error);
      }
    };
    fetchModelDetails();
  }, [db, id]);

  if (!modelDetails) {
    return null;
  }

  return (
    <div>
      <Sidemenu />
      <div className="profile-main">
        <div className="profile-image01">
          <div className="vertical-text">
            <p>
              {modelDetails.Height} &nbsp; B {modelDetails.Bust} &nbsp; W{" "}
              {modelDetails.Waist} &nbsp; H {modelDetails.Hips}
            </p>
            <p>
              SHOE {modelDetails.Shoe} &nbsp; HAIR {modelDetails.Hair}
            </p>
            <p>EYES {modelDetails.Eyes}</p>{" "}
          </div>

          <img src={modelDetails.Image} alt="Model Photo" />
          <h3 className="name-container">
            {modelDetails.Name}
            <br />
            <span className="additional-info">
              <Link to={`/portfolio/${id}`}>PORTFOLIO</Link>
            </span>
            <span className="additional-info">
              <Link to={modelDetails.SocialMediaLink} target="_blank" rel="noopener noreferrer">DIGITALS</Link>
            </span>
            <span className="additional-info">
              <Link to={modelDetails.SocialMediaLink} target="_blank" rel="noopener noreferrer">SOCIAL</Link>
            </span>
          </h3>
        </div>

        <div className="profile-image02">
          <img src={modelDetails.Image2} alt="Model Photo" />

          <h3>
            PORTFOLIO
            <br />
            <span className="additional-info-2">
              <Link to={`/portfolio/${id}`}>VIEW SELECTION</Link>
            </span>
          </h3>
        </div>
      </div>

      <div className="profile-image03">
        <img className="img1" src={modelDetails.Image3} alt="Model Photo" />
        <div className="img-overlay2">
          <Link to={modelDetails.SocialMediaLink} target="_blank" rel="noopener noreferrer" className="head">SOCIAL</Link>
          <Link to={modelDetails.SocialMediaLink} target="_blank" rel="noopener noreferrer" className="second-name">SOCIAL LINK</Link>
        </div>
        <img className="img2" src={modelDetails.Image4} alt="Model Photo" />
      </div>
    </div>
  );
};

export default Profile;
