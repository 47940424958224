
import React from "react";
import Header from "../../../Components/FrontEnd/Header/Header";
import ReactPlayer from "react-player";
import "./Home.css"; // Import the Home.css file

function Home() {
  return (
    <>
      <div className="header">
        {" "}
        <Header />
      </div>
      {/* <div className="video-background-container">
        <ReactPlayer
          url="Assets/Untitled video - Made with Clipchamp (1).mp4"
          playing
          loop
          muted
          width="160%"
          height="210%"
          className="background-video"
        />
      </div> */}

      {/* Adding the background image */}

      <div
        className="image-background-container"
        style={{
          backgroundImage: 'url("/Assets/home_background_image.jpg")',
          height: '100vh',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          // backgroundPosition: 'center',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          zIndex: -1,
        
        }}
      >
        {/* Your content here */}
      </div>

    </>
  );
}

export default Home;