// Header.js
import React, { useState } from "react";
import { Link } from "react-router-dom"; // Import Link from React Router
import "./Header.css";

const Header = () => {
  const [isModelSubMenuOpen, setModelSubMenuOpen] = useState(false);
  const [isMediaSubMenuOpen, setMediaSubMenuOpen] = useState(false);

  const handleModelSubMenuEnter = () => {
    setModelSubMenuOpen(true);
    setMediaSubMenuOpen(false); // Close media submenu when model submenu is opened
  };

  const handleModelSubMenuLeave = () => {
    setModelSubMenuOpen(false);
  };

  const handleMediaSubMenuEnter = () => {
    setMediaSubMenuOpen(true);
    setModelSubMenuOpen(false); // Close model submenu when media submenu is opened
  };

  const handleMediaSubMenuLeave = () => {
    setMediaSubMenuOpen(false);
  };

  return (
    <header className="backend-header">
      <div className="logo">
        <Link to="/modelview">
          <img src="Assets/backend-logo.png" alt="Logo" />
        </Link>
      </div>
      <nav className="nav">
        <ul>
          <li
            className="dropdown"
            onMouseEnter={handleModelSubMenuEnter}
            onMouseLeave={handleModelSubMenuLeave}
          >
            <a href="#">Models</a>
            {isModelSubMenuOpen && (
              <ul className="submenu">
                <li>
                  <Link to="/modelform">Add Models</Link>
                </li>
                <li>
                  <Link to="/modelview">View All Models</Link>
                </li>
                <li>
                  <Link to="/ArchiveView">Archived Models</Link> {/* Add link to Archived Models */}
                </li>
              </ul>
            )}
          </li>
          <li
            className="dropdown"
            onMouseEnter={handleMediaSubMenuEnter}
            onMouseLeave={handleMediaSubMenuLeave}
          >
            <a href="#">Media</a>
            {isMediaSubMenuOpen && (
              <ul className="submenu">
                <li>
                  <Link to="/blogform">Add Media</Link>
                </li>
                <li>
                  <Link to="/blogview">View All Media</Link>
                </li>
              </ul>
            )}
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
