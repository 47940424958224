import React, { useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "react-toastify/dist/ReactToastify.css"; // Import toast styles
import "./ModelForm.css"; // Import CSS file
import { useNavigate } from "react-router-dom";
import Header from "../../../../Components/BackEnd/Backend/Header";

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyAmwKwXojF0ucF1SxgzenRn38UhhJHqz3Y",
  authDomain: "agencysl.firebaseapp.com",
  projectId: "agencysl",
  storageBucket: "agencysl.appspot.com",
  messagingSenderId: "928828761215",
  appId: "1:928828761215:web:d0b58236a291f931500b4a",
  measurementId: "G-TF2XE8K2TJ",
};
firebase.initializeApp(firebaseConfig);

const ModelForm = () => {
  const [formData, setFormData] = useState({
    height: "",
    waist: "",
    bust: "",
    hips: "",
    shoe: "",
    hair: "",
    eye: "",
    image: null,
    image1: null,
    image2: null,
    image3: null,
    image4: null,
    image5: null,
    image6: null,
    image7: null,
    modelName: "",
    gender: "Male", // Default value for gender
    age: "",
    category: "", // Default value for category
    socialMediaLink: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleImageChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const db = firebase.firestore();

    try {
      const storageRef = firebase.storage().ref();
      const imageRefs = [
        formData.image,
        formData.image1,
        formData.image2,
        formData.image3,
        formData.image4,
        formData.image5,
        formData.image6,
        formData.image7,
      ];

      const imageUrlPromises = imageRefs.map(async (imageRef) => {
        if (imageRef) {
          const imageUploadRef = storageRef.child(
            `model_images/${imageRef.name}`
          );
          await imageUploadRef.put(imageRef);
          return imageUploadRef.getDownloadURL();
        }
        return null;
      });

      const imageUrls = await Promise.all(imageUrlPromises);

      await db.collection("models").add({
        Height: formData.height,
        Waist: formData.waist,
        Bust: formData.bust,
        Hips: formData.hips,
        Shoe: formData.shoe,
        Hair: formData.hair,
        Eyes: formData.eye,
        SocialMediaLink: formData.socialMediaLink,
        Image: imageUrls[0],
        Image2: imageUrls[1],
        Image3: imageUrls[2],
        Image4: imageUrls[3],
        Image5: imageUrls[4],
        Image6: imageUrls[5],
        Image7: imageUrls[6],
        Image8: imageUrls[7],
        Name: formData.modelName,
        Gender: formData.gender,
        Age: formData.age,
        Category: formData.category,
      });

      alert("Model details saved successfully!");
      setFormData({
        height: "",
        waist: "",
        bust: "",
        hips: "",
        shoe: "",
        hair: "",
        eye: "",
        image: null,
        image1: null,
        image2: null,
        image3: null,
        image4: null,
        image5: null,
        image6: null,
        image7: null,
        modelName: "",
        gender: "Male", // Reset gender to default value
        age: "",
        category: "", // Reset category to default value
        socialMediaLink: "",
      });
      navigate("/modelview");
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <>
      <Header />
      <div className="model-form-container">
        <h2>Add Model Details</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              name="modelName"
              value={formData.modelName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Gender</label>
            <label>
              <input
                type="radio"
                name="gender"
                value="Male"
                checked={formData.gender === "Male"}
                onChange={handleChange}
                required
              />
              Male
            </label>
            <label>
              <input
                type="radio"
                name="gender"
                value="Female"
                checked={formData.gender === "Female"}
                onChange={handleChange}
                required
              />
              Female
            </label>
          </div>
          <div className="form-group">
            <label>Age</label>
            <input
              type="number"
              name="age"
              value={formData.age}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Category</label>
            <select
              name="category"
              value={formData.category}
              onChange={handleChange}
              required
            >
              <option value="Models">Models</option>
              <option value="Actors">Actors</option>
              <option value="Hair & Makeup Artists">Hair & Makeup Artists</option>
              <option value="Photographers">Photographers</option>
              <option value="Videographers">Videographers</option>
              <option value="Stylists">Stylists</option>
            </select>
          </div>
          <div className="form-group">
            <label>Height</label>
            <input
              type="text"
              name="height"
              value={formData.height}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Waist</label>
            <input
              type="text"
              name="waist"
              value={formData.waist}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Bust</label>
            <input
              type="text"
              name="bust"
              value={formData.bust}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Hips</label>
            <input
              type="text"
              name="hips"
              value={formData.hips}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Shoe</label>
            <input
              type="text"
              name="shoe"
              value={formData.shoe}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Hair color</label>
            <input
              type="text"
              name="hair"
              value={formData.hair}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Eye color</label>
            <input
              type="text"
              name="eye"
              value={formData.eye}
              onChange={handleChange}
              required
            />
          </div>

          <div>
            <div className="form-group">
              <label>Social Media Link</label>
              <input
                type="text"
                name="socialMediaLink"
                value={formData.socialMediaLink}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-group">
            <label>Image 1 (1600 x 1200 px)</label>
            <input
              type="file"
              accept="image/*"
              name="image"
              onChange={handleImageChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Image 2 (1600 x 1200 px)</label>
            <input
              type="file"
              accept="image/*"
              name="image1"
              onChange={handleImageChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Image 3</label>
            <input
              type="file"
              accept="image/*"
              name="image2"
              onChange={handleImageChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Image 4</label>
            <input
              type="file"
              accept="image/*"
              name="image3"
              onChange={handleImageChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Image 5</label>
            <input
              type="file"
              accept="image/*"
              name="image4"
              onChange={handleImageChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Image 6</label>
            <input
              type="file"
              accept="image/*"
              name="image5"
              onChange={handleImageChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Image 7</label>
            <input
              type="file"
              accept="image/*"
              name="image6"
              onChange={handleImageChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Image 8 (Polaroid)</label>
            <input
              type="file"
              accept="image/*"
              name="image7"
              onChange={handleImageChange}
              required
            />
          </div>

          <button type="submit">Add Model</button>
        </form>
      </div>
    </>
  );
};

export default ModelForm;
