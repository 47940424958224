import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { HiArrowSmLeft, HiArrowSmRight } from "react-icons/hi";
import "./Portfolio.css";
import Sidemenu from "../SideMenu/SideMenu.jsx";

const Portfolio = () => {
  const { id } = useParams();
  const [modelDetails, setModelDetails] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // Start with the first image

  
  const firebaseConfig = {
    apiKey: "AIzaSyAmwKwXojF0ucF1SxgzenRn38UhhJHqz3Y",
    authDomain: "agencysl.firebaseapp.com",
    projectId: "agencysl",
    storageBucket: "agencysl.appspot.com",
    messagingSenderId: "928828761215",
    appId: "1:928828761215:web:d0b58236a291f931500b4a",
    measurementId: "G-TF2XE8K2TJ"
  };
  
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  useEffect(() => {
    const fetchModelDetails = async () => {
      try {
        const modelDoc = doc(db, "models", id);
        const docSnap = await getDoc(modelDoc);
        if (docSnap.exists()) {
          setModelDetails(docSnap.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching model details:", error);
      }
    };
    fetchModelDetails();
  }, [db, id]);

  const goToNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % 8);
  };

  const goToPrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + 8) % 8);
  };

  if (!modelDetails) {
    return null;
  }

  const images = [
    modelDetails.Image,
    modelDetails.Image2,
    modelDetails.Image3,
    modelDetails.Image4,
    modelDetails.Image5,
    modelDetails.Image6,
    modelDetails.Image7,
    modelDetails.Image8
  ];

  return (
    <div>
        <Sidemenu />
      {/* Render model details */}
      <div className="portfolio-vertical-text">
        <p>
          {modelDetails.Height} &nbsp; B {modelDetails.Bust} &nbsp; W{" "}
          {modelDetails.Waist} &nbsp; H {modelDetails.Hips}
        </p>
        <p>
          SHOE {modelDetails.Shoe} &nbsp; HAIR {modelDetails.Hair}
        </p>
        <p>EYES {modelDetails.Eyes}</p>
      </div>

      <div className="vertical-text2">
        <p>{modelDetails.Name}</p>
      </div>

      <div className="portfolio-image-slider">
        <button
          className="portfolio-arrow-button left"
          onClick={goToPrevImage}
        >
          <HiArrowSmLeft />
        </button>

        <img
          className="portfolio-img"
          src={images[currentImageIndex]}
          alt={`Image ${currentImageIndex + 1}`}
        />
        <button
          className="portfolio-arrow-button right"
          onClick={goToNextImage}
        >
          <HiArrowSmRight />
        </button>
      </div>
    </div>
  );
};

export default Portfolio;
