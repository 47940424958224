import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "./BlogView.css";
import Header from '../../../../Components/BackEnd/Backend/Header';

const firebaseConfig = {
  apiKey: "AIzaSyAmwKwXojF0ucF1SxgzenRn38UhhJHqz3Y",
  authDomain: "agencysl.firebaseapp.com",
  projectId: "agencysl",
  storageBucket: "agencysl.appspot.com",
  messagingSenderId: "928828761215",
  appId: "1:928828761215:web:d0b58236a291f931500b4a",
  measurementId: "G-TF2XE8K2TJ",
};

firebase.initializeApp(firebaseConfig);

const BlogView = () => {
  const [blogs, setBlogs] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [newBlogImage, setNewBlogImage] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const db = firebase.firestore();
      const data = await db.collection("blogs").get();
      setBlogs(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    };

    fetchData();
  }, []);

  const handleUpdate = (id) => {
    const blogToUpdate = blogs.find((blog) => blog.id === id);
    // Convert date field to Date object
    blogToUpdate.date = blogToUpdate.date.toDate();
    setSelectedBlog(blogToUpdate);
    setEditMode(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this blog?")) {
      try {
        const db = firebase.firestore();
        await db.collection("blogs").doc(id).delete();
        setBlogs(blogs.filter((blog) => blog.id !== id));
        console.log("Blog deleted successfully");
        alert("Blog details saved successfully!");
      } catch (error) {
        console.error("Error deleting blog:", error);
      }
  };
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm(selectedBlog);
    if (Object.keys(errors).length === 0) {
      try {
        const db = firebase.firestore();
        let updatedBlog = selectedBlog;
        if (newBlogImage) {
          const storageRef = firebase.storage().ref();
          const imageRef = storageRef.child(`blog_images/${newBlogImage.name}`);
          await imageRef.put(newBlogImage);
          updatedBlog = {
            ...selectedBlog,
            blogPhoto: await imageRef.getDownloadURL(),
          };
        }
        await db.collection("blogs").doc(updatedBlog.id).set(updatedBlog);
        setEditMode(false);
        console.log("Blog updated successfully");
        alert("Blog details saved successfully!");
      } catch (error) {
        console.error("Error updating blog:", error);
      }
    } else {
      setFormErrors(errors);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "date") {
      setSelectedBlog({ ...selectedBlog, [name]: new Date(value) });
    } else if (name === "image") {
      setNewBlogImage(e.target.files[0]);
    } else {
      setSelectedBlog({ ...selectedBlog, [name]: value });
    }
  };

  const validateForm = (formData) => {
    const errors = {};
    if (!formData.blogName) {
      errors.blogName = "Blog name is required";
    }
    if (!formData.modelName) {
      errors.modelName = "Model name is required";
    }
    if (!formData.date) {
      errors.date = "Date is required";
    }
    return errors;
  };

  return (
    <>
    <Header/>
      <div className="blog-container">
        <h2>Blog Data</h2>
        {editMode && selectedBlog ? (
          <form onSubmit={handleSubmit} className="blog-form">
            <label>Blog Name</label>
            <input
              type="text"
              name="blogName"
              value={selectedBlog.blogName}
              onChange={handleInputChange}
            />
            {formErrors.blogName && (
              <p className="error-message">{formErrors.blogName}</p>
            )}
            <label>Model Name</label>
            <input
              type="text"
              name="modelName"
              value={selectedBlog.modelName}
              onChange={handleInputChange}
            />
            {formErrors.modelName && (
              <p className="error-message">{formErrors.modelName}</p>
            )}
            <label>Date</label>
            <input
              type="date"
              name="date"
              value={
                selectedBlog.date
                  ? selectedBlog.date.toISOString().split("T")[0]
                  : ""
              }
              onChange={handleInputChange}
            />
            {formErrors.date && (
              <p className="error-message">{formErrors.date}</p>
            )}
            <label>Upload New Blog Photo</label>
            <input type="file" name="image" onChange={handleInputChange} />
            <button type="submit">Update</button>
          </form>
        ) : (
          <table className="blog-table">
            <thead>
              <tr>
                <th>Blog Name</th>
                <th>Model Name</th>
                <th>Blog Photo</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {blogs.map((blog) => (
                <tr key={blog.id}>
                  <td>{blog.blogName}</td>
                  <td>{blog.modelName}</td>
                  <td>
                    <img
                      src={blog.blogPhoto}
                      alt="Blog"
                      className="blog-photo"
                    />
                  </td>
                  <td>
                    {new Date(blog.date.seconds * 1000).toLocaleDateString()}
                  </td>
                  <td className="blog-action-buttons">
                    <button
                      className="edit-btn"
                      onClick={() => handleUpdate(blog.id)}
                    >
                      Update
                    </button>
                    <button
                      className="delete-btn"
                      onClick={() => handleDelete(blog.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default BlogView;
