import React from "react";
import "./About.css";
import Sidemenu from "../SideMenu/SideMenu.jsx";
const AboutUs = () => {
  return (
    <div className="about-us-container">
      <Sidemenu />
      <div className="cover-image-container">
        <img
          src="Assets/Banner.jpg"
          alt="Cover Image"
          className="cover-image"
        />
      </div>
      <div className="content_container">
        <h2>ABOUT</h2>

        <h3>Why you should sign with us</h3>
        <br />

        <h4>🔴 Do you need an agency?</h4>

        <p>
          The blunt answer is YES. The wonderful thing about having an agency is
          that we do all the work for you. We find you work, call you and check
          your availability, take care of payment and everything in between. All
          you have to do is answer your phone and make sure to show up. Easy as
          1,2,3. Of course, as your agency we have to charge a percentage for
          our services once a job is confirmed, but this is worth it since you
          probably would not have gotten the job without us . You could
          freelance and do it all on your own. However, in order to do this, you
          have to be able to make it on your own; you must already have the
          right contacts and be able to successfully connect and maintain good
          relationships with clients. You have to be on the ball 24/7, as that
          is what an agent does. It is hard work, plus you have to deliver on
          the other side of the camera as well. All said and done, it is easier
          and and more lucrative to have an agency, for all the time, resources
          and energy you save.
        </p>
        <br />

        <h4>🔴 What is a mother agency?</h4>

        <p>
          Basically, a mother agency is your primary manager (and care giver)
          A.K.A us! WHO IS YOUR MAMA? She is the agency you have a signed
          contract with. You may work with other agencies through her, but she
          is your primary agency, or in other words, your MOTHER agency.
        </p>
        <br />

        <h4>🔴 How do you know we if are a good agency?</h4>

        <p>
          Do you get work? Are we getting you jobs, and if not, is it because of
          you, or us? Or it may be because this is just not your market. Either
          way stay on your toes, be honest with yourself and always keep a good,
          open relationship with your agent. It should be a two-way street.
          Communication is key. If your unhappy, let us know, so that we can
          rectify the situation and get you what you want.
        </p>

        <p>
          Do you get along with your agency? It is important that you feel
          comfortable and not pressured by us. Otherwise you will feel obligated
          and find yourself overstretched. You need to be able to say no.
          Remember, we work for you as well.
        </p>

        <p>
          Do we have unnecessary charges and fees? Most agencies will have a few
          charges such as: portfolio (print/digital), comp cards and or
          website/digital fees. But this is about it. Anything else should be
          closely monitored and always ask why? Make it a point to know what
          everything is. If you can say that the agency has a fair amount of
          work for you; you get along well with everyone at the agency; and
          aren't expected to pay for anything other than some composite cards
          fees and a portfolio, then it is safe to say that you have found a
          good agency.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
